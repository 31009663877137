import {
  WppCard,
  WppGrid,
  WppTypography,
  WppActionButton,
  WppIconRemoveCircle,
  WppIconEdit,
  WppSpinner,
  WppIconPlus,
  WppTooltip,
  WppButton,
  WppMenuContext,
  WppIconMore,
  WppListItem,
  WppIconSync,
  WppIconChevron,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useSetState } from 'react-use'

import { useSyncNewsForTenantApi } from 'api/news/mutations/useSyncNewsForTenantApi'
import { useSyncNewsForTenantCredentialsApi } from 'api/news/mutations/useSyncNewsForTenantCredentialsApi'
import { useCredentialsApi } from 'api/news/queries/useCredentialsApi'
import { AddEditSource } from 'components/addEditSource/AddEditSource'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table } from 'components/common/table/Table'
import { DeleteSource } from 'components/deleteSource/DeleteSource'
import { EmptyState } from 'components/emptyState/EmptyState'
import styles from 'pages/newsSettings/NewsSettings.module.scss'
import { useToast } from 'providers/toast/ToastProvider'
import { Credentials } from 'types/credentianls/credentials'
import { EVENTS } from 'utils/events'
import 'ag-grid-community/styles/ag-grid.css'
import '@platform-ui-kit/components-library/dist/collection/ag-theme-wpp.css'

interface State {
  isAddEditSourceModalOpen: boolean
  isDeleteSourceModalOpen: boolean
  source: null | Credentials
}

export const NewsSettings = () => {
  const { osContext, osApi } = useOs()
  const { enqueueToast } = useToast()
  const { tenant } = osContext
  const { mutateAsync: handleSyncNewsForTenant, isLoading: syncAllNewsInProgress } = useSyncNewsForTenantApi()
  const { mutateAsync: handleSyncNewsForTenantCredentials } = useSyncNewsForTenantCredentialsApi()

  const { isLoading, data: credentials } = useCredentialsApi({
    params: {
      tenantId: tenant.azMeta.organizationsId,
    },
  })

  const handleSyncAll = async () => {
    try {
      await handleSyncNewsForTenant(tenant.azMeta.organizationsId)
      enqueueToast({
        message: 'All OS news channel sources are synced successfully',
        type: 'success',
      })
    } catch (e) {
      enqueueToast({
        message: 'Something went wrong, please try again',
        type: 'error',
      })
    }
  }

  const handleSync = useCallback(
    async (credential: Credentials) => {
      try {
        await handleSyncNewsForTenantCredentials({
          tenantId: tenant.azMeta.organizationsId,
          credentialId: credential.id,
        })
        enqueueToast({
          message: `${credential.name} source is synced successfully`,
          type: 'success',
        })
      } catch (e) {
        enqueueToast({
          message: 'Something went wrong, please try again',
          type: 'error',
        })
      }
    },
    [enqueueToast, handleSyncNewsForTenantCredentials, tenant],
  )

  const [{ isAddEditSourceModalOpen, isDeleteSourceModalOpen, source }, setState] = useSetState<State>({
    isAddEditSourceModalOpen: false,
    isDeleteSourceModalOpen: false,
    source: null,
  })

  const isEmpty = credentials.length === 0

  const columnDefs = useMemo<ColDef<Credentials>[]>(() => {
    return [
      {
        flex: 1,
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        valueGetter: ({ data }) => data!.name,
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        flex: 1,
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        valueGetter: ({ data }) => data!.description,
        tooltipValueGetter: ({ data }) => data!.description,
      },
      {
        flex: 1,
        field: 'site_domain',
        colId: 'site_domain',
        headerName: 'Site',
        valueGetter: ({ data }) => data!.site_domain,
        tooltipValueGetter: ({ data }) => data!.site_domain,
      },
      {
        flex: 1,
        field: 'site_url',
        colId: 'site_url',
        headerName: 'URL',
        valueGetter: ({ data }) => data!.site_url,
        tooltipValueGetter: ({ data }) => data!.site_url,
      },
      {
        field: undefined,
        colId: 'actions',
        cellRenderer: ({ data }) => (
          <WppMenuContext
            className={styles.moreMenu}
            dropdownConfig={{
              appendTo: () => document.body,
              placement: 'bottom-end',
            }}
          >
            <WppActionButton slot="trigger-element" variant="secondary">
              <WppIconMore direction="horizontal" />
            </WppActionButton>

            <WppListItem onWppChangeListItem={() => handleSync(data!)}>
              <WppIconSync slot="left" />
              <span slot="label">Sync now</span>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => setState({ isAddEditSourceModalOpen: true, source: data })}>
              <WppIconEdit slot="left" />
              <span slot="label">Edit</span>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => setState({ isDeleteSourceModalOpen: true, source: data })}>
              <WppIconRemoveCircle slot="left" />
              <span slot="label">Remove</span>
            </WppListItem>
          </WppMenuContext>
        ),
        maxWidth: 60,
      },
    ]
  }, [setState, handleSync])

  const loading = () => (
    <div className={styles.loading}>
      <WppSpinner size="m" />
    </div>
  )
  const tooltipText =
    'It is not possible to add legacy SharePoint sources on workspace. Please use Integrations page of Workspace settings to add new SharePoint integration.'

  return (
    <>
      <AddEditSource
        data={source}
        isOpen={isAddEditSourceModalOpen}
        onClose={() => setState({ isAddEditSourceModalOpen: false })}
      />
      {!!source && (
        <DeleteSource
          source={source}
          open={isDeleteSourceModalOpen}
          onClose={() => setState({ isDeleteSourceModalOpen: false })}
        />
      )}
      <Flex className={styles.header} direction="column" gap={8}>
        <Link
          to="../"
          onClick={() => {
            osApi.analytics.track({
              type: AnalyticsActionType.action,
              payload: EVENTS.ACTIONS.SETTINGS_BACK,
            })
            osApi.analytics.track({
              type: AnalyticsActionType.page,
              payload: EVENTS.PAGE.NEWS,
            })
          }}
        >
          <WppActionButton data-testid="news-settings-back-button">
            <WppIconChevron direction="left" slot="icon-start" />
            Back to News
          </WppActionButton>
        </Link>
        <Flex justify="between">
          <WppTypography type="3xl-heading" data-testid="news-settings-title">
            News Settings
          </WppTypography>
          <Flex>
            {!isEmpty && (
              <WppButton
                variant="secondary"
                onClick={() => {
                  handleSyncAll()

                  osApi.analytics.track({
                    type: AnalyticsActionType.action,
                    payload: EVENTS.ACTIONS.SYNC_NEWS,
                  })
                }}
                loading={syncAllNewsInProgress}
              >
                <WppIconSync slot="icon-start" />
                Sync now
              </WppButton>
            )}
          </Flex>
        </Flex>
      </Flex>
      <WppGrid container fullHeight fullWidth className={styles.container}>
        <WppGrid item all={24}>
          <WppCard className={styles.card}>
            {isLoading ? (
              loading()
            ) : (
              <>
                <Flex justify="between">
                  <Flex direction="column">
                    <WppTypography type="l-strong">Sharepoint sources</WppTypography>
                    <WppTypography type="s-body">Define news feeds from Sharepoint sources</WppTypography>
                  </Flex>
                  {!isEmpty && (
                    <WppTooltip text={tooltipText}>
                      <WppActionButton
                        onClick={() => {
                          setState({ isAddEditSourceModalOpen: true, source: null })
                          osApi.analytics.track({
                            type: AnalyticsActionType.action,
                            payload: EVENTS.ACTIONS.ADD_NEWS_SOURCE,
                          })
                        }}
                        disabled
                      >
                        <WppIconPlus slot="icon-start" />
                        Add source
                      </WppActionButton>
                    </WppTooltip>
                  )}
                </Flex>

                {isEmpty ? (
                  <EmptyState title="No sources found" />
                ) : (
                  <Table
                    columnDefs={columnDefs}
                    rowData={credentials}
                    className={clsx('news-settings', styles.table)}
                  />
                )}
              </>
            )}
          </WppCard>
        </WppGrid>
      </WppGrid>
    </>
  )
}
