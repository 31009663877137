// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S0ld5{display:flex;align-items:center;justify-content:center;height:148px;background-color:var(--wpp-primary-color-100)}`, "",{"version":3,"sources":["webpack://./src/components/imagePlaceholder/imagePlaceholder.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,6CAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 148px;\n  background-color: var(--wpp-primary-color-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `S0ld5`
};
export default ___CSS_LOADER_EXPORT___;
