import { SelectOption } from '@platform-ui-kit/components-library'
import { WppSelect, WppListItem } from '@platform-ui-kit/components-library-react'
import { forwardRef, Ref, ReactNode, ComponentProps } from 'react'

import { useCommonLabelProps } from 'components/common/utils'

type Option = Record<string, any>
export interface SelectProps extends ComponentProps<typeof WppSelect> {
  options: SelectOption[]
  isOptionDisabled?: (option: Option) => boolean
  getOptionValue?: (option: Option) => string | number
  getOptionLabel?: (option: Option) => string
  renderOptionContent?: (option: Option) => ReactNode
  'data-testid'?: string
}

export const Select = forwardRef(function Select(
  {
    options,
    dropdownPosition = 'fixed',
    getOptionValue = option => option.value,
    getOptionLabel = option => option.label,
    renderOptionContent,
    isOptionDisabled = () => false,
    'data-testid': dataTestId,
    labelConfig,
    labelTooltipConfig,
    ...rest
  }: SelectProps,
  ref: Ref<HTMLWppSelectElement>,
) {
  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

  return (
    <WppSelect ref={ref} {...rest} {...labelProps} dropdownPosition={dropdownPosition} data-testid={dataTestId}>
      {options.map(option => {
        const optionValue = getOptionValue(option)

        return (
          <WppListItem key={optionValue} value={optionValue} disabled={isOptionDisabled(option)}>
            {renderOptionContent ? renderOptionContent?.(option) : <span slot="label">{getOptionLabel(option)}</span>}
          </WppListItem>
        )
      })}
    </WppSelect>
  )
}) as (props: SelectProps) => JSX.Element
