import { WppTypography, WppEmptyContent } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'

export const EmptyState = ({ title }: { title?: string }) => {
  return (
    <Flex direction="column" gap={24} align="center" data-testid="empty-data-state">
      <WppEmptyContent />

      <WppTypography type="m-strong">{title}</WppTypography>
    </Flex>
  )
}
