import { TreeChangeEventDetail, TreeType } from '@platform-ui-kit/components-library'
import {
  WppTree,
  WppInput,
  WppSelect,
  WppPopover,
  WppDivider,
  WppActionButton,
} from '@platform-ui-kit/components-library-react'
import { useMemo, useRef, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useDebounceFn } from 'hooks/useDebounceFn'
import styles from 'pages/newsList/filters/SourcesSelect.module.scss'
import { getIfAllAppsSelected, markAllAsSelect, markAllAsUnSelect } from 'pages/newsList/filters/utils'
import { useSourceTreeData } from 'pages/utils'

export enum SOURCE_TYPE {
  APP = 'app',
  SITE = 'site',
}

export interface SelectedTreeItem extends TreeType {
  type: string
}

interface Props {
  onSelectHandler: (selectedItems: SelectedTreeItem[]) => void
}

export const SourcesSelect = ({ onSelectHandler }: Props) => {
  const popoverRef = useRef<HTMLWppPopoverElement>(null)

  const [search, setSearch] = useState<string>('')

  const { treeData, onChangeTreeHandler, isLoading } = useSourceTreeData()

  const { isAllSelected, selectedCount } = useMemo(() => {
    let isAllSelected = getIfAllAppsSelected(treeData)

    const selectedCount = treeData.reduce(
      (count, item) => count + (item.children?.filter(child => child.selected).length || 0),
      0,
    )

    return { isAllSelected, selectedCount }
  }, [treeData])

  const placeholder = useMemo(() => {
    const count = isAllSelected ? 'All' : selectedCount ? selectedCount : 'No'
    const suffix = count === 1 ? ' is' : 's are'

    return isLoading ? 'Sources...' : `${count} source${suffix} selected`
  }, [isAllSelected, selectedCount, isLoading])

  const setSearchDebounced = useDebounceFn((searchString?: string) => {
    setSearch(searchString?.trim() || '')
  }, 400)

  const onTreeChangeHandler = (detail: TreeChangeEventDetail) => {
    onSelectHandler(detail.selectedItems as SelectedTreeItem[])
    onChangeTreeHandler(detail.treeState)
  }

  const extractChildren = (arr: TreeType[]) => {
    let flatMap: SelectedTreeItem[] = []

    arr.forEach((item: any) => {
      flatMap.push(item, ...item.children)
    })

    return flatMap
  }

  const handleAllSelect = () => {
    const updatedTree = markAllAsSelect(treeData)
    onSelectHandler(extractChildren(updatedTree))
    onChangeTreeHandler(updatedTree)
  }

  const handleAllUnSelect = () => {
    const updatedTree = markAllAsUnSelect(treeData)
    onSelectHandler([])
    onChangeTreeHandler(updatedTree)
  }

  return (
    <WppPopover
      ref={popoverRef}
      config={{
        triggerElementWidth: true,
        appendTo: () => document.querySelector('#micro-app') || document.body, //Required for eventListeners inside dropDown container
      }}
      data-testid="sources-select"
    >
      <WppSelect
        size="s"
        required
        slot="trigger-element"
        className={styles.select}
        placeholder={placeholder}
        dropdownConfig={{ triggerElementWidth: false }}
      />
      <Flex direction="column" className={styles.dropdownWrapper}>
        <WppInput
          type="search"
          placeholder="Search"
          disabled={isLoading}
          className={styles.searchInput}
          data-testid="sources-search-input"
          onWppChange={({ detail }) => setSearchDebounced(detail.value)}
        />
        <WppDivider />
        <WppTree
          multiple
          search={search}
          data={treeData}
          withItemsTruncation
          loading={isLoading}
          skeletonNumberItems={2}
          data-testid="sources-tree"
          className={styles.treeWrapper}
          onWppChange={({ detail }) => onTreeChangeHandler(detail)}
        />
        <WppDivider />
        <Flex justify="between" className={styles.actions}>
          <WppActionButton onClick={handleAllSelect} disabled={isAllSelected || isLoading}>
            Select all
          </WppActionButton>
          <WppActionButton onClick={handleAllUnSelect} disabled={!(isAllSelected || selectedCount) || isLoading}>
            Clear all
          </WppActionButton>
        </Flex>
      </Flex>
    </WppPopover>
  )
}
