// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o2qqk{display:flex}.OWNJf{display:inline-flex}.Awzjx{flex-direction:column}.jMOE1{align-items:flex-start}.A2d7b{align-items:center}.FW31x{align-items:flex-end}.m0V3n{justify-content:start}.eETvV{justify-content:center}.JbwEr{justify-content:flex-end}.Sc8Bp{justify-content:space-between}.HIGBH{justify-content:space-around}.LWcvS{flex-wrap:nowrap}.f09Gh{flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/components/common/flex/Flex.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,mBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,kBAAA,CAGF,OACE,oBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,wBAAA,CAGF,OACE,6BAAA,CAGF,OACE,4BAAA,CAGF,OACE,gBAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  display: flex;\n}\n\n.inline {\n  display: inline-flex;\n}\n\n.column {\n  flex-direction: column;\n}\n\n.align-start {\n  align-items: flex-start;\n}\n\n.align-center {\n  align-items: center;\n}\n\n.align-end {\n  align-items: flex-end;\n}\n\n.justify-start {\n  justify-content: start;\n}\n\n.justify-center {\n  justify-content: center;\n}\n\n.justify-end {\n  justify-content: flex-end;\n}\n\n.justify-between {\n  justify-content: space-between;\n}\n\n.justify-around {\n  justify-content: space-around;\n}\n\n.nowrap {\n  flex-wrap: nowrap;\n}\n\n.wrap {\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `o2qqk`,
	"inline": `OWNJf`,
	"column": `Awzjx`,
	"align-start": `jMOE1`,
	"align-center": `A2d7b`,
	"align-end": `FW31x`,
	"justify-start": `m0V3n`,
	"justify-center": `eETvV`,
	"justify-end": `JbwEr`,
	"justify-between": `Sc8Bp`,
	"justify-around": `HIGBH`,
	"nowrap": `LWcvS`,
	"wrap": `f09Gh`
};
export default ___CSS_LOADER_EXPORT___;
