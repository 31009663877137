import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { FormProvider } from 'react-hook-form'

import { useCreateCredentialApi } from 'api/news/mutations/useCreateCredentialApi'
import { useUpdateCredentialApi } from 'api/news/mutations/useUpdateCredentialApi'
import {
  defaultValues,
  validationSchema,
  mapFormToCredentials,
  mapCredentialsToForm,
  DESCRIPTION_MAX_CHARACTERS,
} from 'components/addEditSource/utils'
import { Flex } from 'components/common/flex/Flex'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormCountriesSelect } from 'components/form/formSelect/FormCountriesSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { FormSideModal } from 'components/surface/sideModal/FormSideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'providers/toast/ToastProvider'
import { Credentials } from 'types/credentianls/credentials'

interface Props {
  data: MayBeNull<Credentials>
  isOpen: boolean
  onClose: () => void
}

export const AddEditSource = ({ data, onClose, isOpen }: Props) => {
  const queryClient = useQueryClient()
  const { mutateAsync: handleCreateCredential } = useCreateCredentialApi()
  const { mutateAsync: handleUpdateCredential } = useUpdateCredentialApi()
  const { osContext } = useOs()
  const { tenant } = osContext
  const { enqueueToast } = useToast()

  const form = useForm({
    defaultValues: data ? mapCredentialsToForm(data) : defaultValues,
    validationSchema,
  })

  const isMaxCredentialError = (error: unknown) => {
    return (error as any)?.response?.data?.detail === 'The OS supports up to 10 news channel sources'
  }

  const onCreateSubmit = async (values: any) => {
    try {
      await handleCreateCredential({
        tenantId: tenant.azMeta.organizationsId,
        credential: mapFormToCredentials(values),
      })

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CREDENTIALS] })

      onClose()

      enqueueToast({
        message: 'News source is added successfully',
        type: 'success',
      })
    } catch (e) {
      enqueueToast({
        message: isMaxCredentialError(e)
          ? 'The OS supports up to 10 news channel sources'
          : 'Something went wrong, please try again',
        type: 'error',
      })
    }
  }

  const onUpdateSubmit = async (values: any) => {
    try {
      await handleUpdateCredential({
        tenantId: tenant.azMeta.organizationsId,
        credential: mapFormToCredentials(values),
        credentialId: data?.id || '',
      })

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CREDENTIALS] })

      onClose()
      enqueueToast({
        message: 'News source is updated successfully',
        type: 'success',
      })
    } catch (e) {
      enqueueToast({
        message: 'Something went wrong, please try again',
        type: 'error',
      })
    }
  }

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form

  return (
    <FormProvider {...form}>
      <FormSideModal
        data-testid="news-source-side-modal"
        open={isOpen}
        formProps={{
          onSubmit: handleSubmit(data ? onUpdateSubmit : onCreateSubmit),
        }}
        size="m"
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={() => {
          reset()
        }}
        disableOutsideClick
      >
        <WppTypography slot="header" type="2xl-heading">
          {data ? 'Edit SharePoint source' : 'Add SharePoint source'}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24} style={{ marginBottom: 24 }}>
          <FormTextInput name="name" labelConfig={{ text: 'Name' }} placeholder="Name" required />
          <FormTextareaInput
            name="description"
            labelConfig={{
              text: 'Description',
            }}
            placeholder="Description"
            charactersLimit={DESCRIPTION_MAX_CHARACTERS}
            warningThreshold={DESCRIPTION_MAX_CHARACTERS}
          />
          <FormTextInput name="title" labelConfig={{ text: 'Sharepoint site' }} placeholder="Site title" required />
          <FormTextInput name="url" labelConfig={{ text: 'Sharepoint URL' }} placeholder="https://" required />
          <FormTextInput name="clientId" labelConfig={{ text: 'Client ID' }} required />
          <FormTextInput name="clientSecret" type="password" labelConfig={{ text: 'Client secret' }} required />
          <FormTextInput name="realm" labelConfig={{ text: 'Realm' }} required />
          <FormTextInput
            name="resourceInformationValue"
            labelConfig={{ text: 'Resource Information Value' }}
            required
          />
          <FormCountriesSelect
            type="multiple"
            name="countryAlpha2Codes"
            labelConfig={{ text: 'Country' }}
            placeholder="Select country"
            data-testid="country-select"
          />
          <FormCheckbox
            name="notificationsEnabled"
            required
            labelConfig={{
              text: 'Notify members of latest news',
            }}
          />
          <FormCheckbox
            name="openInsideOs"
            required
            labelConfig={{
              text: 'Open news inside the OS',
            }}
          />
          <FormCheckbox
            name="isPrimary"
            required
            labelConfig={{
              text: 'Star all news from this source',
            }}
          />
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton
            variant="secondary"
            size="m"
            type="button"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} data-testid="save-button">
            {data ? 'Save' : 'Add source'}
          </WppButton>
        </Flex>
      </FormSideModal>
    </FormProvider>
  )
}
