import { WppButton, WppEmptyError, WppIconRefresh, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'

export const ErrorLoadingState = () => {
  return (
    <Flex direction="column" gap={24} align="center" data-testid="error-loading-state">
      <WppEmptyError />

      <Flex direction="column" gap={8} align="center">
        <WppTypography type="m-strong">Page couldn’t be loaded</WppTypography>
        <WppTypography type="s-body">Please refresh or try again later</WppTypography>
      </Flex>

      <WppButton onClick={() => window.location.reload()} variant="primary">
        <WppIconRefresh slot="icon-start" />
        Refresh page
      </WppButton>
    </Flex>
  )
}
