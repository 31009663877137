import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

export const NewsCardWrapper = ({
  id,
  postUrl,
  children,
  openInsideOs,
}: {
  id: string
  postUrl: string
  children: ReactNode
  openInsideOs: boolean
}): JSX.Element =>
  openInsideOs ? (
    <Link to={`/${id}`}>{children}</Link>
  ) : (
    <a href={postUrl} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
