import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { useAsyncFn } from 'react-use'

import { useUpdateNewsItemApi } from 'api/news/mutations/useUpdateNewsItemApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { NEWS_STATUSES } from 'pages/utils'
import { useToast } from 'providers/toast/ToastProvider'

export const useNewsActivationDeactivation = () => {
  const { enqueueToast } = useToast()
  const { osContext } = useOs()

  const {
    tenant: {
      azMeta: { organizationsId: tenantId },
    },
  } = osContext

  const queryClient = useQueryClient()
  const { mutateAsync: handleUpdateNewsItem } = useUpdateNewsItemApi()

  const [{ loading: isLoading }, handleActivationDeactivation] = useAsyncFn(
    async (id: string, isPaused: boolean) => {
      try {
        await handleUpdateNewsItem({
          tenantId,
          newsId: id || '',
          newsItem: {
            status: isPaused ? NEWS_STATUSES.ACTIVE : NEWS_STATUSES.INACTIVE,
          },
        })

        await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.NEWS] })

        enqueueToast({
          message: `News item is ${isPaused ? 'activated' : 'paused'} successfully`,
          type: 'success',
        })
      } catch (e) {
        enqueueToast({
          message: 'Something went wrong, please try again',
          type: 'error',
        })
      }
    },
    [handleUpdateNewsItem, tenantId, queryClient, enqueueToast],
  )

  return {
    handleActivationDeactivation,
    isLoading,
  }
}
