import {
  WppTag,
  WppCard,
  WppTooltip,
  WppIconPlay,
  WppListItem,
  WppIconEdit,
  WppIconMore,
  WppSkeleton,
  WppIconPause,
  WppTypography,
  WppMenuContext,
  WppActionButton,
  WppIconFavourites,
  WppIconFavouritesFilled,
} from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { ImagePlaceholder } from 'components/imagePlaceholder/imagePlaceholder'
import styles from 'components/newsCard/NewsCard.module.scss'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermissions'
import { useNewsActivationDeactivation } from 'hooks/useNewsActivationDeactivation'
import { useNewsPrimaryNonPrimary } from 'hooks/useNewsPrimaryNonPrimary'
import { NEWS_STATUSES } from 'pages/utils'
import { Market } from 'types/masterData/markets'
import { News } from 'types/news/news'
import { formatDate } from 'utils/dates'

interface Props {
  newsItem: News
  locale: string
  onEdit: () => void
  normalizedCountries: Record<string, Market>
}

export const NewsCard = ({ newsItem, locale, onEdit, normalizedCountries }: Props) => {
  const { banner_image, author, title, created_at: date, id, status, is_primary } = newsItem
  const isPaused = status === NEWS_STATUSES.INACTIVE

  const { hasPermission } = useHasPermission()
  const hasAccessToNewsSettings = hasPermission(Permission.NewsSettingsManage)

  const { handlePrimaryNonPrimary, isLoading: isLoadingPrimaryNonPrimary } = useNewsPrimaryNonPrimary()
  const { handleActivationDeactivation, isLoading: isLoadingActivationDeactivation } = useNewsActivationDeactivation()

  const isLoading = isLoadingActivationDeactivation || isLoadingPrimaryNonPrimary

  let countries
  if (Object.keys(normalizedCountries).length && newsItem.country_alpha2_codes.length) {
    countries = newsItem.country_alpha2_codes.map(code => normalizedCountries[code].name).sort()
  }

  return (
    <WppCard interactive className={styles.card}>
      {isLoading ? (
        <NewsCardSkeleton />
      ) : (
        <>
          {hasAccessToNewsSettings && (
            <Flex gap={4} slot="actions">
              <WppTooltip text={`Make ${is_primary ? 'non-starred' : 'starred'}`}>
                <WppActionButton
                  variant="secondary"
                  style={{ zIndex: 5 }}
                  className={styles.whiteActionButton}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    handlePrimaryNonPrimary(id, is_primary)
                  }}
                  data-testid="primary-non-primary"
                >
                  {is_primary ? <WppIconFavouritesFilled /> : <WppIconFavourites />}
                </WppActionButton>
              </WppTooltip>
              <WppMenuContext
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                dropdownConfig={{
                  appendTo: () => document.body,
                  placement: 'bottom-end',
                }}
              >
                <WppActionButton
                  variant="secondary"
                  style={{ zIndex: 5 }}
                  slot="trigger-element"
                  className={styles.whiteActionButton}
                  data-testid="more-menu"
                >
                  <WppIconMore direction="horizontal" />
                </WppActionButton>
                <div data-testid="more-menu-list">
                  <WppListItem onWppChangeListItem={() => onEdit()}>
                    <WppIconEdit slot="left" />
                    <span slot="label">Edit</span>
                  </WppListItem>
                  <WppListItem
                    onWppChangeListItem={() => {
                      handleActivationDeactivation(id, isPaused)
                    }}
                  >
                    {isPaused ? <WppIconPlay slot="left" /> : <WppIconPause slot="left" />}
                    <span slot="label">{isPaused ? 'Activate' : 'Pause'}</span>
                  </WppListItem>
                </div>
              </WppMenuContext>
            </Flex>
          )}

          {banner_image ? (
            <img className={styles.cover} src={banner_image} alt="" data-testid="news-image" />
          ) : (
            <ImagePlaceholder />
          )}
          <div className={styles.content}>
            <div className={styles.author}>
              <WppTypography type="s-midi">{author}</WppTypography>
            </div>
            <div>
              <WppTypography type="m-strong" data-testid="news-title">
                {title}
              </WppTypography>
            </div>
            <Flex className={styles.footer} justify={hasAccessToNewsSettings && countries ? 'between' : 'end'}>
              {hasAccessToNewsSettings && countries && (
                <WppTooltip text={countries.join(', ')}>
                  <WppTag
                    variant="neutral"
                    label={`${countries[0]}${countries.length > 1 ? ', +' + (countries.length - 1) : ''}`}
                    data-testid="countries-tag"
                  />
                </WppTooltip>
              )}
              <WppTypography className={styles.date} type="xs-body" data-testid="date-label">
                {formatDate(date, locale)}
              </WppTypography>
            </Flex>
          </div>
        </>
      )}
    </WppCard>
  )
}

export function NewsCardSkeleton() {
  return (
    <Flex
      gap={24}
      align="center"
      direction="column"
      data-testid="news-card-skeleton"
      className={styles.skeletonWrapper}
    >
      <WppSkeleton height={148} />
      <Flex direction="column" gap={8} className={styles.skeletonContent}>
        <WppSkeleton width="30%" height={20} />
        <WppSkeleton width="95%" height={20} />
        <WppSkeleton width="95%" height={20} />
        <WppSkeleton width="95%" height={20} />
      </Flex>
    </Flex>
  )
}
