// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sOIr2{min-height:calc(100vh - var(--wpp-os-header-height));background-color:var(--wpp-grey-color-100)}.OZxuk{--x-content-ratio: calc(1280 / 640);--content-height: clamp( /* min height */ 640px, /* preffered height */ 100vw / var(--x-content-ratio), /* max height */ var(--wpp-os-content-max-width) / var(--x-content-ratio) );min-width:1280px;max-width:var(--wpp-os-content-max-width);margin:0 auto;padding:24px 28px}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAGA,OACE,oDAAA,CACA,0CAAA,CAGF,OACE,mCAAA,CACA,mLAAA,CAMA,gBAAA,CACA,yCAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":["$min-home-app-width: 1280;\n$min-home-app-height: 640;\n\n.container {\n  min-height: calc(100vh - var(--wpp-os-header-height));\n  background-color: var(--wpp-grey-color-100);\n}\n\n.innerContent {\n  --x-content-ratio: calc(#{$min-home-app-width} / #{$min-home-app-height});\n  --content-height: clamp(\n    /* min height */ #{$min-home-app-height}px,\n    /* preffered height */ 100vw / var(--x-content-ratio),\n    /* max height */ var(--wpp-os-content-max-width) / var(--x-content-ratio)\n  );\n\n  min-width: #{$min-home-app-width}px;\n  max-width: var(--wpp-os-content-max-width);\n  margin: 0 auto;\n  padding: 24px 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sOIr2`,
	"innerContent": `OZxuk`
};
export default ___CSS_LOADER_EXPORT___;
