import { AppProps } from '@wpp-open/core'
import { OsProvider } from '@wpp-open/react'
import { StrictMode } from 'react'

import { App } from 'app/App'
import { ApiProvider } from 'providers/api/ApiProvider'

export const Root = (props: AppProps) => {
  return (
    <StrictMode>
      <OsProvider {...props}>
        <ApiProvider>
          <App />
        </ApiProvider>
      </OsProvider>
    </StrictMode>
  )
}
