import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { useAsyncFn } from 'react-use'

import { useUpdateNewsItemApi } from 'api/news/mutations/useUpdateNewsItemApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'providers/toast/ToastProvider'

export const useNewsPrimaryNonPrimary = () => {
  const { osContext } = useOs()
  const {
    tenant: {
      azMeta: { organizationsId: tenantId },
    },
  } = osContext
  const queryClient = useQueryClient()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateNewsItem } = useUpdateNewsItemApi()

  const [{ loading: isLoading }, handlePrimaryNonPrimary] = useAsyncFn(
    async (id: string, primary: boolean) => {
      try {
        await handleUpdateNewsItem({
          tenantId,
          newsId: id || '',
          newsItem: {
            is_primary: !primary,
          },
        })

        await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.NEWS] })

        enqueueToast({
          message: `News item is marked as ${primary ? 'non-starred' : 'starred'}`,
          type: 'success',
        })
      } catch (e) {
        enqueueToast({
          message: 'Something went wrong, please try again',
          type: 'error',
        })
      }
    },
    [handleUpdateNewsItem, tenantId, queryClient, enqueueToast],
  )

  return {
    handlePrimaryNonPrimary,
    isLoading,
  }
}
