import { TreeType } from '@platform-ui-kit/components-library'

export const markAllAsSelect = (tree: TreeType[]): TreeType[] => {
  return tree.map(item => {
    if (item.children) {
      return { ...item, selected: true, children: markAllAsSelect(item.children) }
    }
    return { ...item, selected: true }
  })
}

export const markAllAsUnSelect = (tree: TreeType[]): TreeType[] => {
  return tree.map(item => {
    if (item.children) {
      return { ...item, selected: false, children: markAllAsUnSelect(item.children) }
    }
    return { ...item, selected: false }
  })
}

export const getIfAllAppsSelected = (tree: TreeType[]): boolean => {
  return !tree.some(parentNode => parentNode.selected === false)
}
