import { WppTypography, WppEmptyNothingFound } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/emptyState/ErrorState.module.scss'

export const EmptySearchResult = ({ title, subTitle }: { title: string; subTitle: string }) => {
  return (
    <Flex direction="column" gap={24} align="center" data-testid="empty-search-result">
      <WppEmptyNothingFound />

      <Flex direction="column" gap={8} align="center">
        <WppTypography type="m-strong">{title}</WppTypography>
        <WppTypography type="s-body" className={styles.subTitle}>
          {subTitle}
        </WppTypography>
      </Flex>
    </Flex>
  )
}
