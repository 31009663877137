import * as zod from 'zod'

import { NEWS_STATUSES } from 'pages/utils'
import { News, NewsItemForm, NewsItemUpdateDTO } from 'types/news/news'

export const defaultValues: NewsItemForm = {
  isPaused: false,
  isPrimary: false,
  isOpenInsideOs: false,
  countryAlpha2Codes: [],
}

export const mapNewsItemToForm = (data: News): NewsItemForm => ({
  isPrimary: data.is_primary,
  isOpenInsideOs: data.open_inside_os,
  isPaused: data.status === NEWS_STATUSES.INACTIVE,
  countryAlpha2Codes: data.country_alpha2_codes || [],
})

export const mapFormToNewsItem = (data: NewsItemForm): NewsItemUpdateDTO => ({
  is_primary: data.isPrimary,
  country_alpha2_codes: data.countryAlpha2Codes,
  status: data.isPaused ? NEWS_STATUSES.INACTIVE : NEWS_STATUSES.ACTIVE,
})

export const validationSchema = zod.object({
  isPaused: zod.boolean(),
  isPrimary: zod.boolean(),
  countryAlpha2Codes: zod.string().array(),
})
