// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wr6Ke{margin-bottom:28px}.yos_Z{padding:64px 0}.aLzaB{width:320px}.g6hPJ{display:inline-block;overflow:hidden;text-overflow:ellipsis}.oAEOQ::part(card){display:flex;flex-direction:column;height:340px;padding:0}`, "",{"version":3,"sources":["webpack://./src/pages/newsList/NewsList.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CAGF,OACE,cAAA,CAGF,OACE,WAAA,CAGF,OACE,oBAAA,CACA,eAAA,CACA,sBAAA,CAIA,mBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,SAAA","sourcesContent":[".header {\n  margin-bottom: 28px;\n}\n\n.emptyStateWrapper {\n  padding: 64px 0;\n}\n\n.selectContainer {\n  width: 320px;\n}\n\n.countrySelectOptionLabel {\n  display: inline-block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.card {\n  &::part(card) {\n    display: flex;\n    flex-direction: column;\n    height: 340px;\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `wr6Ke`,
	"emptyStateWrapper": `yos_Z`,
	"selectContainer": `aLzaB`,
	"countrySelectOptionLabel": `g6hPJ`,
	"card": `oAEOQ`
};
export default ___CSS_LOADER_EXPORT___;
