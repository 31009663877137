// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IP9AH{white-space:pre-wrap;text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/emptyState/ErrorState.module.scss"],"names":[],"mappings":"AAAA,OACE,oBAAA,CACA,iBAAA","sourcesContent":[".subTitle {\n  white-space: pre-wrap;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subTitle": `IP9AH`
};
export default ___CSS_LOADER_EXPORT___;
