import { useQueryClient, FetchQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { QueryFetcher, QueryFetcherParams, QueryFetcherResponse } from 'api/common/types'
import { useStableCallback } from 'hooks/useStableCallback'

export function createUseFetch<F extends QueryFetcher, P = QueryFetcherParams<F>, R = QueryFetcherResponse<F>>({
  queryKey,
  fetcher,
}: {
  queryKey: string
  fetcher: F
}) {
  return function useFetch(
    options: Omit<FetchQueryOptions<R, AxiosError>, 'queryKey' | 'queryFn' | 'queryHash' | 'queryKeyHashFn'> = {},
  ) {
    const queryClient = useQueryClient()

    return useStableCallback((params: P = {} as P) =>
      queryClient.fetchQuery({
        queryKey: [queryKey, params],
        queryFn: async ({ signal }: { signal: AbortSignal }) => fetcher(params)(signal) as Promise<R>,
        ...options,
      } as unknown as FetchQueryOptions<R, AxiosError>),
    )
  }
}
