import {
  WppGrid,
  WppCard,
  WppInput,
  WppSkeleton,
  WppTypography,
  WppSegmentedControl,
  WppSegmentedControlItem,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'
import { useSetState } from 'react-use'

import { useNewsApi } from 'api/news/queries/useNewsApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { Select } from 'components/common/select/Select'
import { EditNewsItem } from 'components/editNewsItem/EditNewsItem'
import { EmptySearchResult } from 'components/emptyState/EmptySearchResult'
import { EmptyState } from 'components/emptyState/EmptyState'
import { NewsCard, NewsCardSkeleton } from 'components/newsCard/NewsCard'
import { NewsCardWrapper } from 'components/newsCard/NewsCardWrapper'
import { Delay } from 'constants/delay'
import { Permission } from 'constants/permission'
import { useCountries } from 'hooks/useCountries'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useHasPermission } from 'hooks/useHasPermissions'
import { SelectedTreeItem, SOURCE_TYPE, SourcesSelect } from 'pages/newsList/filters/SourcesSelect'
import styles from 'pages/newsList/NewsList.module.scss'
import { NEWS_STATUSES } from 'pages/utils'
import { News } from 'types/news/news'

enum TABS {
  ALL = 'all',
  PRIMARY = 'primary',
  PAUSED = 'paused',
}
interface State {
  search: string
  selectedTab: TABS
  siteIds: string[]
  statuses: NEWS_STATUSES[]
  newsItem: MayBeNull<News>
  countryAlpha2Codes: string[]
  is_primary: MayBeNull<boolean>
  isEditNewsItemModalOpen: boolean
}

const defaultFilterValues = {
  search: '',
  siteIds: [],
  is_primary: null,
  countryAlpha2Codes: [],
  statuses: [NEWS_STATUSES.ACTIVE],
}

export const NewsList = () => {
  const { osContext } = useOs()
  const { tenant } = osContext

  const { hasPermission } = useHasPermission()
  const hasAccessToNewsSettings = hasPermission(Permission.NewsSettingsManage)

  const [
    { search, siteIds, newsItem, statuses, is_primary, selectedTab, countryAlpha2Codes, isEditNewsItemModalOpen },
    setState,
  ] = useSetState<State>({
    newsItem: null,
    selectedTab: TABS.ALL,
    isEditNewsItemModalOpen: false,
    ...defaultFilterValues,
  })

  const { isFetching, data: news } = useNewsApi({
    params: {
      search,
      siteIds,
      statuses,
      is_primary,
      countryAlpha2Codes,
      tenantId: tenant.azMeta.organizationsId,
    },
  })
  const { countriesOptions, isLoading: isCountriesLoading, normalizedCountries } = useCountries()

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '' }),
    Delay.Search,
  )

  const emptyStateComponent = useMemo(() => {
    const isFiltersApplied = !!siteIds.length || !!countryAlpha2Codes.length

    const getTitle = () => {
      if (isFiltersApplied) return 'No results'
      if (search) return `There are no results for "${search}"`
      return {
        [TABS.ALL]: 'No news yet',
        [TABS.PRIMARY]: 'No starred news yet',
        [TABS.PAUSED]: 'No paused news yet',
      }[selectedTab]
    }

    const title = getTitle()
    const subTitle = isFiltersApplied
      ? 'Your filter setting shows no results, \nplease apply another filter'
      : 'Try searching by a different keyword'

    return search ? <EmptySearchResult title={title} subTitle={subTitle} /> : <EmptyState title={title} />
  }, [selectedTab, search, siteIds.length, countryAlpha2Codes.length])

  const content = useCallback(
    (isFetching: boolean, news: News[]) => {
      if (isFetching) {
        return (
          <WppGrid container fullHeight fullWidth all={24} data-testid="news-loading-container">
            {Array(8)
              .fill(undefined)
              .map((_, index) => {
                return (
                  <WppGrid item all={6} key={index}>
                    <WppCard className={styles.card}>
                      <NewsCardSkeleton />
                    </WppCard>
                  </WppGrid>
                )
              })}
          </WppGrid>
        )
      } else if (!news.length) {
        return (
          <Flex justify="center" className={styles.emptyStateWrapper}>
            {emptyStateComponent}
          </Flex>
        )
      } else {
        return (
          <WppGrid container fullHeight fullWidth all={24} data-testid="news-grid">
            {news.map(newsItem => (
              <WppGrid item all={6} key={newsItem.id}>
                <NewsCardWrapper openInsideOs={newsItem.open_inside_os} id={newsItem.id} postUrl={newsItem.post_url}>
                  <NewsCard
                    newsItem={newsItem}
                    locale={osContext.userDetails.dateLocale}
                    normalizedCountries={normalizedCountries}
                    onEdit={() => setState({ isEditNewsItemModalOpen: true, newsItem })}
                  />
                </NewsCardWrapper>
              </WppGrid>
            ))}
          </WppGrid>
        )
      }
    },
    [normalizedCountries, osContext.userDetails.dateLocale, setState, emptyStateComponent],
  )

  const setTabDependFilterValue = (value: TABS) => {
    switch (value) {
      case TABS.ALL:
        return { statuses: [NEWS_STATUSES.ACTIVE], is_primary: null }
      case TABS.PRIMARY:
        return { statuses: [NEWS_STATUSES.ACTIVE], is_primary: true }
      case TABS.PAUSED: {
        return { statuses: [NEWS_STATUSES.INACTIVE], is_primary: null }
      }
    }
  }
  const sourceSelectHandler = useCallback(
    (items: SelectedTreeItem[]) => {
      let siteIds: string[] = items.filter(item => item?.type === SOURCE_TYPE.SITE).map(site => site.id as string)
      setState({ siteIds })
    },
    [setState],
  )

  const resetFilters = () => {
    const { search, siteIds, countryAlpha2Codes } = defaultFilterValues
    setState(() => ({ search, siteIds, countryAlpha2Codes }))
  }
  return (
    <>
      <EditNewsItem
        data={newsItem}
        isOpen={isEditNewsItemModalOpen}
        onClose={() => setState({ isEditNewsItemModalOpen: false })}
      />
      <Flex className={styles.header} gap={16} direction="column">
        <Flex justify="between">
          <WppTypography type="3xl-heading" data-testid="news-page-title">
            News
          </WppTypography>
        </Flex>
        <Flex gap={20}>
          <WppInput
            size="s"
            type="search"
            value={search}
            placeholder="Search news by title"
            data-testid="news-card-search-input"
            onWppChange={({ detail }) => setSearchDebounced(detail.value)}
          />

          {hasAccessToNewsSettings && (
            <>
              <WppSegmentedControl
                size="s"
                value={selectedTab}
                data-testid="search-by-status-segment-control"
                onWppChange={({ detail: { value } }) => {
                  value && setState({ selectedTab: value as TABS, ...setTabDependFilterValue(value as TABS) })
                  resetFilters()
                }}
              >
                <WppSegmentedControlItem value={TABS.ALL}>All</WppSegmentedControlItem>
                <WppSegmentedControlItem value={TABS.PRIMARY}>Starred</WppSegmentedControlItem>
                <WppSegmentedControlItem value={TABS.PAUSED}>Paused</WppSegmentedControlItem>
              </WppSegmentedControl>

              <SourcesSelect onSelectHandler={sourceSelectHandler} key={selectedTab} />

              <div className={styles.selectContainer} data-testid="news-cards-filter-by-country">
                {isCountriesLoading ? (
                  <WppSkeleton height={32} />
                ) : (
                  <Select
                    size="s"
                    withSearch
                    withFolder
                    type="multiple"
                    placeholder="Country"
                    value={countryAlpha2Codes}
                    options={countriesOptions}
                    onWppChange={event => {
                      setState({ countryAlpha2Codes: event.detail.value })
                    }}
                    data-testid="search-by-country-select"
                    renderOptionContent={({ label, logoThumbnail }) => (
                      <Flex slot="label" gap={8}>
                        <Avatar size="xs" src={logoThumbnail?.url || ''} name={label} style={{ minWidth: '24px' }} />
                        <span className={styles.countrySelectOptionLabel}>{label}</span>
                      </Flex>
                    )}
                  />
                )}
              </div>
            </>
          )}
        </Flex>
      </Flex>

      {content(isFetching, news)}
    </>
  )
}
